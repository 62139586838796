<template>
  <div>
    <div class="scroll" ref="scroll">
      <div ref="scrollRow" v-for="(item, index) in data" class="event">
        <div class="title">
          <div class="circleIcon"></div>
          <div class="name">{{ item.name }}</div>
          <div class="time">{{ item.time }}</div>
        </div>
        <div class="detail">
          {{ item.text }}
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  name: 'xxyEvent',
  props: {
    data: {
      type: Array,
      default: []
    }
  },
  computed: {
  
  },
  created() {
    setInterval(this.rollStart, 3000);
  },
  methods:{
    rollStart() {
      const scroll = this.$refs.scroll;
      var row = this.$refs.scrollRow;

      if(!row || !scroll){
        return
      }
      row = row[0]
      const step = 1; // 设置每帧滚动的步长，可以调整平滑度
      const smoothScroll = () => {
        if (scroll.scrollTop + scroll.clientHeight < scroll.scrollHeight) {
          scroll.scrollTop += step;
          // 如果还没有达到下一个元素的位置，继续请求下一帧
          if (scroll.scrollTop % row.clientHeight !== 0) {
            requestAnimationFrame(smoothScroll);
          }
        }
      };
      
      if(scroll.scrollTop + scroll.clientHeight >= scroll.scrollHeight){
        scroll.scrollTop = 0;
      }else{
        smoothScroll();
      } 
    },
  }
}
</script>

<style lang="scss" scoped>
.event{
  color: #fff;
  padding-top: 10px;
  .title{
    display: flex;
    .circleIcon{
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: #0B9FEC;
      margin: auto 5px;
    }

    .name{
      font-size: 1rem;
    }

    .time{
      flex: 1;
      text-align: right;
      font-size: 0.8rem;
    }
  }

  .detail{
    margin-left: 20px;
    font-size: 0.9rem;
  }
}

.scroll{
  height: 33vh;
  overflow-y: auto;
}
</style>