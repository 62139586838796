<template>
  <div class="container">
    <div class="boardRow" >
      <div class="header">
          <span style="width: 20%;">#</span>
          <span style="width: 40%;" @click="rollStart">加油站</span>
          <span class="unit" style="width: 40%;">{{ unit }}</span>
        </div>
    </div>
    <div class="scroll" ref="scroll">
      <div class="boardRow" ref="scrollRow" v-for="(item,index) in data">
          <div>
            <span style="width: 20%;">NO.{{ index+1 }}
            </span>
            <span style="width: 40%;">{{ item.station_name }}</span>
            <span class="unit" style="width: 40%;">{{item.value}} {{ unit }}</span>
          </div>
          <xxyProgress :percentage="item.proportion"></xxyProgress>
      </div>
    </div>
  </div>
</template>

<script>
import xxyProgress from '@/components/xxy-progress.vue'
export default {
  name: 'xxySalesRankingBoard',
  components: {
    xxyProgress
  },
  props: {
    data: {
      type: Array,
      default: []
    },
    unit: {
      type: String,
      default: '升'
    }
  },
  data() {
    return {
      timer: null
    }
  },
  watch: {
  },
  created() {
    this.begin()
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    begin(){
      this.timer = setInterval(this.rollStart, 3000);
    },
    rollStart() {
      const scroll = this.$refs.scroll;
      var row = this.$refs.scrollRow;

      if(!row || !scroll){
        return this.changeView()
      }
      row = row[0]
      const step = 1; // 设置每帧滚动的步长，可以调整平滑度

      const smoothScroll = () => {
        if (scroll.scrollTop + scroll.clientHeight < scroll.scrollHeight) {
          scroll.scrollTop += step;
          // 如果还没有达到下一个元素的位置，继续请求下一帧
          if (scroll.scrollTop % row.clientHeight !== 0) {
            requestAnimationFrame(smoothScroll);
          }
        }
      };
      if(scroll.scrollTop + scroll.clientHeight >= scroll.scrollHeight){
        this.changeView()
      }else{
        smoothScroll();
      } 
    },
    changeView(){
      clearInterval(this.timer);
      setTimeout(() => {
        this.$refs.scroll.scrollTop = 0
        this.$emit('changeRank');
      }, 3000);
    }
  }
}
</script>


<style lang="scss" scoped>
.container {
  height: 100%;

  .boardRow{
    color: #fff;
    padding: 3px 5px;
    line-height: 1.7rem;
    font-size: 1rem;

    .header{
      background: #02055E;
    }

    span{
      display: inline-block;
    }
    
    .unit{
      text-align: right;
    }
  }

  .scroll{
    height: 34vh;
    overflow-y: auto;
  }
}

</style>