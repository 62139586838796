<template>
  <div>
    <!-- 登录按钮 -->
    <i class="el-icon-lock login" @click="showLogin"></i>
    <!-- 弹窗 -->
    <el-dialog
      title="登录"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :modal="false"
      >
      
      <!-- 表单内容 -->
      <el-form :model="form" :rules="rules" ref="loginForm">
        <el-form-item label="油企编号" prop="no">
          <el-input v-model="form.no"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="username">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="看板id" prop="bid">
          <el-input v-model="form.bid"></el-input>
        </el-form-item>
      </el-form>
      <!-- 弹窗底部操作按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm" :loading="loading">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'login',
  data() {
    return {
      dialogVisible: false, // 控制弹窗显示
      form: {
        no: 'c20220505145814', // 油企编号
        username: '',  // 账号
        password: '',  // 密码
        bid: ''   // 看板id
      },
      rules: {
        no: [
          { required: true, message: '请输入油企编号', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        bid: [
          { required: true, message: '请输入看板id', trigger: 'blur' }
        ],
      },
      loading: false
    };
  },
  created(){
    this.form.no = localStorage.getItem('enterprise_no')
    this.form.username = localStorage.getItem('busername')
    this.form.password = localStorage.getItem('bpassword')
  },
  methods: {
    ...mapActions({
      login: 'login/login',
      setId: 'params/setId'
    }),
    showLogin() {
      this.dialogVisible = true; // 显示弹窗
      var id = this.$route.query.id
      if(id){
        this.form.bid = id
      }
    },
    handleClose() {
      this.dialogVisible = false; // 关闭弹窗
    },
    submitForm() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          // 在这里处理表单提交逻辑
          this.handleEnterpriseNo()
          localStorage.removeItem('boradId')
          localStorage.setItem('busername', this.form.username);
          localStorage.setItem('bpassword', this.form.password);
          this.login(this.form).then(res => {
            this.handleClose()
            this.$emit('loginSuc', this.form.bid)
          }).finally(() => {
            this.loading = false
          })
        }else{
          return false
        }
      });
    },
    handleEnterpriseNo(){
      if (this.form.no.length !== 15) {
        this.$baseMessage('请检查下油企编号输入是否有误！', 'error')
        return
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: right;
}

.login{
  font-size: 16px;
  padding-left: 20px;
  cursor: pointer;
}

.login:hover{
  color: #2d6bdf;
  font-size: 18px;
}
</style>