import axios from 'axios'
import store from '@/store'
import Vue from 'vue'

let service = null
let enterprise_no = localStorage.getItem('enterprise_no');
let token = localStorage.getItem('token');

const handleRequest = (config) => {
    let enterprise_no = localStorage.getItem('enterprise_no');
    if(!enterprise_no){
        console.log('登录状态异常', enterprise_no)
        return Promise.reject('登录状态异常')
    }
    if (!service) initInstance()

    if(config['url'] != '/admin/login/index' && config['url'] != '/admin/DataScreen/index' ){
      if(!config['params'] ) config['params'] = {};
      config['params']['start_time'] = store.getters['params/start_time'];
      config['params']['end_time'] = store.getters['params/end_time'];
      config['params']['station_no'] = store.getters['params/station_no'];
      config['params']['id'] = localStorage.getItem('boradId');
      if(!config['params']['id']) return Promise.reject('未确定看板')
    }
    
    return service(config)
  }

const initInstance = () => {
    let enterprise_no = localStorage.getItem('enterprise_no');
    service = axios.create({
        baseURL: `https://${enterprise_no}.xxyoil.com`,
        timeout: 0,
        responseType: 'json',
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
    })

    service.interceptors.request.use(
        config => {
          let token = localStorage.getItem('token');
          if (token) {
            config.headers['x-access-token'] = token;
          }
          return config
        },
        error => {
          return Promise.reject(error)
        }
      )
      
      service.interceptors.response.use(
        response => {
          const res = response.data
          if((res.code != 200 && res.code != 11108) || res.status != 1) {
            Vue.prototype.$baseMessage(res.msg, 'error')
            return Promise.reject(res)
          }
          return res
        },
        error => {
          return Promise.reject(error)
        }
      )
}

export default handleRequest
