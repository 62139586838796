<template>
   <div class="LeftView">
        <div class="top">
            <div class="card">
                <div class="boxHeader">
                    <span class="title" @click="fetchData">会员概况</span>
                </div>
                <div class="box ">
                    <div class="memberReviewBox" v-for="item in memberReviewData">
                        <div class="f1f">
                            <div class="f1">
                                <img class="img" :src="require('@/assets/member_review_icon.png')" alt="">
                            </div>
                            <div class="f2f" style="flex-direction: column;">
                                <div class="f1f title" style="align-items: center;">
                                    {{ item.title }}
                                </div>
                                <div class="f1 data">
                                    <span>{{ item.value }}</span>
                                    <span class="unit">个</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="card">
                <div class="boxHeader">
                    <div class="title">支付方式占比
                        <span class="bold">{{ popType == 1 ? '[消费]':'[充值]' }}</span>
                    </div>
                    <div>
                        <xxyButton v-model="popType" :active="1">消费</xxyButton>
                        <xxyButton v-model="popType" :active="2">充值</xxyButton>
                    </div>
                </div>
                <div ref="ProportionOfPayType" style="width: 100%;height: 85%;">
                </div>
            </div>
        </div>
        <div class="middle">
            <div class="boxHeader">
                <span class="title">会员充值消费情况</span>
            </div>
            <div class="boxContent">
                    <xxySalesDetailBoard :data="consumptionReview.recharge_review">充值</xxySalesDetailBoard>
                    <xxySalesDetailBoard :data="consumptionReview.consumption_review">消费</xxySalesDetailBoard>
            </div>
        </div>
        <div class="bottom">
            <div class="card">
                <div class="boxHeader">
                    <div class="title">
                        用户增长趋势
                        <span class="bold">{{ trendType == 1 ? '[每月]':'[每日]' }}</span>
                    </div>
                    <div>
                        <xxyButton v-model="trendType" :active="1">月</xxyButton>
                        <xxyButton v-model="trendType" :active="2">日</xxyButton>
                    </div>
                </div>
                <div class="box" ref="memberIncreceTrend">
                </div>
            </div>
        </div>
   </div>
</template>

<script>

import xxySalesDetailBoard from '@/components/xxy-sales-detail-board.vue'
import xxyProgress from '@/components/xxy-progress.vue'
import xxyButton from '@/components/xxy-button.vue'


import { getLeft } from '@/utils/api.js'
import { color } from 'echarts'

export default {
    name: 'LeftView',
    components: {
        xxySalesDetailBoard,
        xxyProgress,
        xxyButton
    },
    data() {
        return {
            config1: {
                number: [100],
                content: '{nt}',
                style: {
                    fontSize: '28',
                    fill: '#fff',
                    fontWeight: 'bold',
                },
                textAlign: 'left',
            },
            memberReviewData: [
                {
                    title: '会员总数',
                    value: 0
                },
                {
                    title: '新注册会员数',
                    value: 0
                },
                {
                    title: '活跃会员数',
                    value: 0
                },
                {
                    title: '今日充值',
                    value: 0
                }
            ],
            pieData: {
                consumption_proportion: [],
                recharge_proportion:[]
            },
            pie: [],
            popType: 1,
            consumptionReview: {
                consumption_review: [],
                recharge_review: []
            },
            // 用户增长趋势
            trendType: 1,
            trendData: [],
            increceMemberTrendData: {
                day: [],
                month: [],
            },
            pieChart: null,
            trendChart: null
        }
    },
    computed: {
    },
    watch: {
        pieData(val) {
            this.generatePie();
        },
        popType(val) {
            this.generatePie();
        },
        increceMemberTrendData(){
            this.generateTrend();
        },
        trendType(val) {
            this.generateTrend();
        },
    },
    created() {
    },
    mounted() {
    },
    methods:{
        intervalChange(){
            this.popType = this.popType == 1? 2: 1;
            this.trendType = this.trendType == 1? 2: 1;
        },
        generatePie(){
            if(this.popType === 1) {
                this.pie = this.pieData.consumption_proportion;
            } else {
                this.pie = this.pieData.recharge_proportion;
            }
            this.initPie();
        },
        generateTrend(){
            if(this.trendType === 1) {
                this.trendData = this.increceMemberTrendData.month;
            } else {
                this.trendData = this.increceMemberTrendData.day;
            }
            this.initTrend();
        },
        fetchData(){
            getLeft().then(res => {
                this.memberReviewData = res.data.memberReview;
                this.pieData = res.data.proportionOfPayType;
                this.consumptionReview = res.data.consumptionReview;
                this.increceMemberTrendData = res.data.increceMemberTrend;
            }).catch(err => {
                console.log('err', err)
            })
        },
        initPie(){
            if(!this.pie.length) return

            var chartDom = this.$refs.ProportionOfPayType;
            if (!this.pieChart) {
                this.pieChart = this.$echarts.init(chartDom);
                //this.pieChart.dispose(); 
            }
            var option;
            option = {
                title: {
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'right',
                    top: 'middle',
                    textStyle: {
                        color: '#fff'
                    },
                    // 使用回调函数
                    formatter:  (name)  => {
                        var proportion = this.pie.find(item => item.name === name);
                        proportion = proportion? proportion.proportion: 0;
                        return  name + '  ' + proportion;
                    }
                },
                color: [ "#329dff",
                    "#00489b",
                    "#0050b3",
                    "#36cfca",
                    "#0a34f9",
                    "#66c2ff",
                    "#004080",
                    "#3385ff",
                    "#66ccff",
                    "#99ccff",
                    "#003366"],// 饼图各块颜色
                series: [
                    {
                        name: '支付方式',
                        type: 'pie',
                        radius: '60%',
                        center: ['30%', '50%'],
                        data: this.pie,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        label: {
                             show: false, //隐藏标示文字
                        }
                    }
                ]
                };
                option && this.pieChart.setOption(option);
        },
        initConsumptionReview(){
            var chartDom = this.$refs.consumptionReview;
            var myChart = this.$echarts.init(chartDom);
            var option;

            option = {
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                data: [120, 200, 150, 80, 70, 110, 130],
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
                }
            ]
            };

            option && myChart.setOption(option);
        },
        initTrend() {
            var chartDom = this.$refs.memberIncreceTrend;
            if (!this.trendChart) {
                this.trendChart = this.$echarts.init(chartDom);
            }
            var option;

            option = {
                tooltip: {
                trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    orient: 'horizontal',
                    textStyle: {
                        color: '#fff',
                        fontSize: '16px'
                    },
                    top: 10,
                    // 使用回调函数
                    formatter:  (name)  => {
                        var prefix =  this.trendType == 1 ? '每月':'每日'
                        return  prefix + name ;
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: this.trendData.map(item => item.time)
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        lineStyle: {
                            color: 'rgba(0, 0, 0, 0.1)' // 设置为更淡的颜色
                        },
                        splitLine: {
                            lineStyle:{
                                color: 'rgba(255,255,255, 0.15)'
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '新增会员数',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                        width: 2,
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                { offset: 0, color: '#3DE6FF' },
                                { offset: 1, color: '#1968FF' }
                            ])
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: 'rgba(61, 230, 255, 0.5)' },
                                { offset: 1, color: 'rgba(25, 104, 255, 0.5)' }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.trendData.map(item => item.count)
                    }
                ]
            };

      option && this.trendChart.setOption(option);
    }

    }
}
</script>
<style lang="scss" scoped>
.LeftView {
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
        display: flex;
        flex: 4;
    }
    .middle {
        flex: 5;
    }
    .bottom {
        display: flex;
        flex: 4;
    }
}

.memberReviewBox{
    height: 50%; 
    width: 50%;
    display: inline-block;

    .img{
        width: 100%; 
        height: 100%; 
        object-fit: contain;
    }

    .title{
        color: #7AB6FF;
        font-size: 0.9rem;
       
    }

    .data{
        color: #fff;
        font-size: 1.5rem;
        .unit{
            font-size: 0.9rem;
        }
    }
}

.boxContent {
    display: flex;
    div {
        flex: 1
    }
}
</style>
