<template>
  <dv-full-screen-container>
    <Head @fetchAll="fetchAll" @startFetch="startFetch"></Head>
    <div class="container">
      <div class="left">
        <LeftView ref="left"></LeftView>
      </div>
      <div class="middle">
        <MiddleView ref="middle"></MiddleView>
      </div>
      <div class="right">
        <RightView ref="right"></RightView>
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import Head from './Head.vue';
import LeftView from './LeftView.vue';
import MiddleView from './MiddleView.vue';
import RightView from './RightView.vue';
import { mapActions } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    Head,
    LeftView,
    MiddleView,
    RightView
  },
  data() {
    return {
      timer1: null,
      timer2: null,
    }
  },
  beforeDestroy() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
  },
  created(){
    this.startFetch();
    this.fetchAll();
  },
  methods:{
    ...mapActions({
      add30s: 'params/add30Seconds',
    }),
    startFetch(){
      this.timer1 = setInterval(()=>{
        this.fetchAll();
      }, 30000);

      this.timer2 = setInterval(()=>{
        this.intervalChange();
      }, 10000);
    },
    fetchAll(){
      this.$refs.left.fetchData();
      this.$refs.middle.fetchData();
      this.$refs.right.fetchData();
    },
    intervalChange(){
      this.$refs.left.intervalChange();
      this.$refs.middle.intervalChange();
      this.$refs.right.intervalChange();
    }
  }
}
</script>
<style lang="scss" scoped>
  .container {
    display: flex;
    height: 92%;
    background-image: url('~@/assets/bgx3.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 10px;

    .left {
      flex: 6;
    }

    .middle {
      flex: 6;
    }
    
    .right {
      flex: 5;
    }
  }
</style>
